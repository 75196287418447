import {
  Card, CardContent, Grid, Typography
} from '@material-ui/core';
import {
  Button, InfoLine, PageHeader, Wrapper
} from 'components';
import { CustomCardTitle } from 'components/ModuleDetail/EducationalSheet';
import React, { useCallback, useEffect, useState } from 'react';
import { SkeletonDetailUser, SkeletonNoResult } from 'components/_commons/Skeletons';
import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { Redirect, useParams } from 'react-router-dom';
import { UserAccountService } from 'services';
import styled from 'styled-components';
import { KeyCloakUtils } from 'utils';
import { PATHS } from 'utils/constants';
import { formatDate } from 'utils/helpers';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';
import { translate } from 'utils/translation';
import { faIdCard, faUserCircle } from '@fortawesome/pro-regular-svg-icons';

const StyledUserDetailHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    color: #ffffff;
    font-size: 2.6rem;
    border-radius: 50%;
    border: 2px solid #02587E;
    background-color: #017db8;
  }

  button {
    margin-left: 2rem;
  }
`;

const TextAddress = ({ address }) => {
  if (!address || !address.city) {
    return (
      <Typography>
        {translate('errors.noAddress')}
      </Typography>
    );
  }

  return (
    <Typography style={{ fontWeight: 'bold' }}>
      {`${address.address1 || ''} ${address.address2 ? `(${address.address2})` : ''}`}
      <br />
      {`${address.postalCode || ''} ${address.city || ''}`}
    </Typography>
  );
};

const PageProfile = () => {
  const { userId } = useParams();
  const { userStore } = useStores();
  const { userConnected, isConnected } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();

  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const keycloak = KeyCloakUtils.currentKeycloakInstance();

  const resetPassword = useCallback(() => UserAccountService.resetPassword()
    .then(() => {
      CookiesHelper.setChangePasswordCookie('true');
      keycloak.logout();
    })
    .catch((err) => {
      enqueueSnackbar((err && err.message) || err, { variant: 'error' });
    }), [enqueueSnackbar, keycloak]);

  const displayModalUpdatePassword = useCallback(
    () => showModal({
      type: 'WARNING',
      title: 'userDetails.action.updatePassword',
      text: 'userDetails.confirmation.updatePassword',
      onConfirm: () => resetPassword(),
      buttonCancel: 'button.cancel',
      buttonConfirm: 'button.confirm',
      confirmOnly: true
    }),
    [resetPassword, showModal]
  );

  const getUserAccountDetail = useCallback(() => {
    UserAccountService.getUserAccountDetail(userId)
      .then((resp) => setProfile(resp))
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [userId, enqueueSnackbar]);

  useEffect(() => {
    !userConnected && setIsLoading(false);

    if (userConnected) {
      getUserAccountDetail();
    }
  }, [userConnected, getUserAccountDetail]);

  if (isLoading) return <SkeletonDetailUser />;
  if (!isConnected) return <Redirect to={PATHS.HOME} />;

  if (!profile || !profile.person) {
    return (
      <SkeletonNoResult error="errors.noProfile" />
    );
  }

  const {
    creationDate,
    person: {
      firstName, lastName, occupation, address, companyName, institution, contact
    } = {}
  } = profile;
  const userFullName = `${firstName} ${lastName}`;

  return (
    <Wrapper>
      <StyledUserDetailHeader>
        <span>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</span>
        <PageHeader title={userFullName} withoutTranslation />
      </StyledUserDetailHeader>

      <Grid container spacing={4} wrap="wrap">
        <Grid item sm={6} xs={12}>
          <Card>
            <CustomCardTitle icon={faUserCircle} title="pageUserDetail.myInfos" />
            <CardContent>
              <InfoLine info={occupation} label="common.occupation" />
              <InfoLine info={companyName} label="common.company" />
              <InfoLine info={institution} label="common.institution" />
              <InfoLine
                displayIf={creationDate}
                info={formatDate(creationDate)}
                label="common.creationDate"
              />
              <InfoLine
                displayIf={address && address.city}
                info={<TextAddress address={address} />}
                label="common.address"
              />
            </CardContent>
          </Card>
        </Grid>

        {contact && (
          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle icon={faIdCard} title="common.contact" />
              <CardContent>
                <InfoLine
                  displayIf={contact.firstContactEmail}
                  info={contact.firstContactEmail}
                  label="common.email"
                  type="email"
                />
                <InfoLine
                  displayIf={contact.phoneNumber}
                  info={contact.phoneNumber}
                  label="common.phone"
                  type="phone"
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <Button onClick={displayModalUpdatePassword}>
            {translate('button.userDetails.updatePassword')}
          </Button>
        </Grid>

      </Grid>
    </Wrapper>
  );
};

export default PageProfile;